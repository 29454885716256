/* ======================= Product Details Section Css Start ==================== */
.product-details {
    &__thumb {
        border-radius: 8px 8px 0 0;
        overflow: hidden;
    }
    &__buttons {
        background-color: hsl(var(--section-bg));
        padding: 32px;
        border-radius: 0 0 8px 8px;
    }
    &__desc {
        margin-top: 40px;
        margin-bottom: 24px;
        @include lg-screen {
            margin-top: 32px;
        }
        @include md-screen {
            margin-top: 24px;
        }
    }
    &__item {
        margin-bottom: 24px;
    }
}

/* Product List */
.product-list {
    list-style: disc;
    padding-left: 17px;
    &__item {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/* More Item  */
.more-item {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid hsl(var(--gray-five));
    &__content {
        gap: 15px;
    }
    &__item {
        .link {
            @extend .contributor-item__thumb; 
            &:hover {
                &::before {
                    height: 100%;
                    visibility: visible;
                    opacity: 1;
                    z-index: 1;
                }
                img {
                    transform: scale(1.1);
                }
            }
        }
        img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
        }
    }
}

/* Product Details Sidebar Css Start */
.product-sidebar {
    padding: 24px 32px;
    border-radius: 8px;
    @include xl-screen {
        padding: 24px;
    }
    @include lg-screen {
        padding: 24px 16px;
    }
    &__top {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid hsl(var(--gray-five));
    }
}
.btn-has-dropdown {
    font-weight: 600;
    padding-right: 16px;
    position: relative;
    &::before {
        position: absolute;
        content: "\f107";
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: hsl(var(--heading-color));
        @include font-16; 
        transition: 0.2s linear;
    }
}

.license-dropdown {
    box-shadow: var(--box-shadow);
    border: 1px solid hsl(var(--gray-five));
    padding: 16px;
    border-radius: 8px;
    max-width: 300px;
    transform: scale(.9);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    top: calc(100% - 15px);
    transition: .2s linear;
    background-color: hsl(var(--white));
    z-index: 2;
    &::before {
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        top: -7px;
        left: 8px;
        transform: rotate(45deg);
        border: 1px solid hsl(var(--gray-five));
        background-color: hsl(var(--white));
        border-radius: 3px;
        border-bottom: 0;
        border-right: 0;
    }
    &.active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
}


.author-details {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid hsl(var(--gray-five));
    margin-top: 24px;
    &__thumb {
        width: 72px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: hsl(var(--white));
        @include lg-screen {
            width: 60px;
            height: 60px;
        }
    }
}

/* badge list Css Start */
.badge-list {
    margin-left: 80px;
    @include lg-screen {
        margin-left: 68px;
    }
    &__item {
        width: 48px;
        height: 52px;
        background-image: url(../images/shapes/polygon-shape.png);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    &--sm {
        .badge-list {
            &__item {
                width: 36px;
                height: 40px;
                background-image: url(../images/shapes/polygon-shape.png);
                background-repeat: no-repeat;
                background-size: cover;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
            }
        }
    }
}
/* badge list Css End */

.meta-attribute {
    margin-top: 32px;
    @include lg-screen {
        margin-top: 24px;
    }
    &__item {
        display: flex;
        align-items: start;
        gap: 24px;
        margin-bottom: clampCal(16, 24);
        .name {
            font-size: 1rem;
            color: hsl(var(--heading-color)); 
            width: 140px;
            flex-shrink: 0; 
        }
        .details {
            @include font-14; 
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            > a {
                font-size: inherit;
            }
        }
    }
}
/* Product Details Sidebar Css End */

/* Product Review Css Start */
.product-review, .user-comment {
    background-color: hsl(var(--white));
    padding: 16px clamp(1rem, 0.391rem + 1.268vw, 1.5rem);
    border: 1px solid hsl(var(--border-color));
    margin-bottom: clamp(1rem, 0.391rem + 1.268vw, 1.5rem);
    border-radius: 8px;
}

.product-review:last-child, .user-comment:last-child {
    margin-bottom: 0;
}

.product-review__top {
    padding-bottom: 16px;
    margin-bottom: clamp(1rem, 0.391rem + 1.268vw, 1.5rem);
    border-bottom: 1px solid hsl(var(--border-color));
}

.product-review__rating {
    gap: 12px;
}

@media screen and (max-width: 991px) {
    .product-review__rating {
        gap: 4px;
    }
}

@media screen and (max-width: 1199px) {
    .product-review__reason, .product-review__subject, .product-review__date, .product-review__desc {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 767px) {
    .product-review__reason, .product-review__subject, .product-review__date, .product-review__desc {
        font-size: 0.875rem;
    }
}

.product-review__subject {
    color: hsl(var(--heading-color));
    font-weight: 700;
}

.product-review__user:hover {
    text-decoration: underline;
}

.author-reply, .user-comment__profile {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid hsl(var(--border-color));
    padding-top: clamp(1rem, 0.391rem + 1.268vw, 1.5rem);
    margin-top: clamp(1rem, 0.391rem + 1.268vw, 1.5rem);
}

.author-reply__thumb, .user-comment__thumb {
    width: 56px;
    height: 56px;
}

@media screen and (max-width: 575px) {
    .author-reply__thumb, .user-comment__thumb {
        width: 40px;
        height: 40px;
    }
}

.author-reply__content, .user-comment__info {
    width: calc(100% - 56px);
    padding-left: 16px;
}

@media screen and (max-width: 575px) {
    .author-reply__content, .user-comment__info {
        width: calc(100% - 40px);
        padding-left: 8px;
    }
}

.author-reply__name, .user-comment__name {
    font-size: 1.125rem;
    margin-bottom: 0;
}

.author-reply__response, .user-comment__purchase {
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 16px;
    display: block;
}

@media screen and (max-width: 767px) {
    .author-reply__response, .user-comment__purchase {
        font-weight: 500;
    }
}

.author-reply__desc {
    margin-bottom: 12px;
}

@media screen and (max-width: 767px) {
    .author-reply__desc {
        font-size: 0.875rem;
    }
}

.author-reply__desc:last-of-type {
    margin-bottom: 0;
}

.review-reply {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    padding-top: 32px;
    border-top: 1px solid hsl(var(--border-color));
}

.review-reply__thumb {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
}

.review-reply__content {
    width: calc(100% - 56px);
    padding-left: 12px;
}

.review-reply__button {
    margin-top: 12px;
}

/* Product Review Css End */
/* ======================= Product Details Section Css End ==================== */